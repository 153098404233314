import envVar, { getCurrentEnv, setEnvironments } from '@availity/env-var';

const externalEnvironments = {
  'test-ext': [/send-receive-edi-mft/, /test-mft/, /test-files/],
  'qa-ext': [/send-receive-edi-stage-mft/, /qa-mft/, /qa-files/],
  'qap-ext': [/send-receive-edi-qap-mft/, /qap-mft/, /qap-files/],
  'prod-ext': [/^mft/, /^files/],
  'qap-apps': [/send-receive-edi-qap-mft/, /qap-mft/, /qap-files/],
};

// Add external environments
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
setEnvironments(externalEnvironments);

export const IS_EXTERNAL = envVar({
  local: false,
  test: false,
  qa: false,
  qap: false,
  prod: false,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  'qa-ext': true,
  'qap-ext': true,
  'test-ext': true,
  'prod-ext': true,
});

export const IS_LOCAL = getCurrentEnv() === 'local';

export const IS_PROD = getCurrentEnv() === 'prod' || getCurrentEnv() === 'prod-ext';

export const IS_TEST = getCurrentEnv() === 'test' || getCurrentEnv() === 'test-ext';

export const AVAILITY_CUSTOMER_ID = IS_PROD ? '21907' : '1194';

// Permissions
export const MFT_PERMISSION = '7372';

export const OLD_MFT_PERMISSION = '1606';

export const INTERNAL_USER_PERMISSION = '5210';

export const MFT_TEMPORARY_ACCESS_URL_PERMISSON = '7896';

export const MFT_FILE_RESTORE_PERMISSON = '7897';

export const MFT_VIEW_MAILBOXES_PERMISSON = '7898';

export const MFT_SUPER_USER_PERMISSON = '7899';

// MBX Values
export const SUBMITTER = 'SUBMITTER';
export const RECEIVER = 'RECEIVER';
export const ERA = 'ERASENDER';
export const EROOM = 'E-ROOMS';
export const MULTIOFFICE = 'MULTI_OFFICE';

// Secondary Values
export const CURRENT = 'CURRENT';
export const ARCHIVE = 'ARCHIVE';

// Search types
export const MBX = 'MBX';
export const USER = 'USER';

export type SearchFormValues = {
  searchType: SearchTypeValue;
  mbxType: MailboxTypeValue;
  secondaryMbxType: SecondaryMbxTypeValue;
  mbx: string;
  username: string;
};

export const TEMPORARY_ACCESS_URL_HELP = 'upcj4DoE3C';

export const MAX_PUBLIC_KEYS = 10;

export const PUBLIC_KEY_REGEX = /ssh-rsa AAAA[\d+/A-Za-z]+={0,3} ([^@]+@[^@]+)/;
