import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Container, Alert, Card, CardContent, CardHeader, Button, Input, FileIcon, TextField, FormLabel, Radio, RadioGroup, FormControlLabel } from '@availity/element'
import { useForm, Controller } from 'react-hook-form';
import Spaces, { SpacesLink } from '@availity/spaces';
import BlockUi from 'react-block-ui';
import { useCopyToClipboard, useTimeoutFn } from 'react-use';

import { getTempAccessUrl } from '@/api';

import { TEMPORARY_ACCESS_URL_HELP } from '../constants';

type FormValues = {
  customerId: string;
  secondaryType: string;
  mbxType: string;
};

const initialValues: FormValues = {
  customerId: '',
  secondaryType: 'CURRENT',
  mbxType: 'SUBMITTER',
};

export const GetTempAccessUrl = ({
  customerId: propsCustomerId,
  mbxType: propsMbxType,
}: {
  customerId?: string;
  mbxType?: string;
}) => {
  const { mutate: getTempAccess, data = '', isLoading, isError, reset } = useMutation(getTempAccessUrl);

  const {
    formState: { errors },
    handleSubmit,
    register,
    control
  } = useForm<FormValues>({
    defaultValues: {
      customerId: propsCustomerId || initialValues.customerId,
      secondaryType: initialValues.secondaryType,
      mbxType: propsMbxType || initialValues.mbxType,
    }
  });
  const onSubmit = (values: FormValues) => {
    getTempAccess({ customerId: values.customerId, secondaryType: values.secondaryType, mbxType: values.mbxType });
  };

  const [clipboardState, copyToClipboard] = useCopyToClipboard();
  const [copied, setCopied] = useState(false);

  const fn = () => {
    setCopied(false);
  };
  // eslint-disable-next-line unicorn/no-unreadable-array-destructuring
  const [, , timeoutReset] = useTimeoutFn(fn, 3000);

  const handleCopy = () => {
    copyToClipboard(data);
    setCopied(true);
    timeoutReset();
  };

  return (
    <Container>
      <Card className="mt-4">
        <CardHeader title="Get Temporary Access URL"
          action={<Spaces clientId="test" variables={{ types: ['FILE'] }} spaceIds={[TEMPORARY_ACCESS_URL_HELP]}>
            <SpacesLink
              style={{ display: 'inline-block' }}
              showName={false}
              clientId="test"
              icon
              spaceId={TEMPORARY_ACCESS_URL_HELP}
              type=""
            />
          </Spaces>} />
        <CardContent>
          <BlockUi blocking={isLoading}>
            <Container>
              {isError && <Alert severity="error">
                There was an error getting the temporary access url
              </Alert>
              }
              <form onSubmit={handleSubmit(onSubmit)} >
                <Controller control={control}
                  name="mbxType"
                  render={({ field: { onChange, ref } }) => (
                    <>
                      <FormLabel component="legend" id="mailbox-type-label" required>
                        Mailbox Type
                      </FormLabel>
                      <RadioGroup row onChange={onChange} defaultValue={propsMbxType || initialValues.mbxType} ref={ref}>
                        <FormControlLabel control={<Radio />} label="Submitter" value="SUBMITTER" className="mt-0" />
                        <FormControlLabel control={<Radio />} label="Receiver" value="RECEIVER" />
                        <FormControlLabel control={<Radio />} label="ERA Sender" value="ERASENDER" />
                        <FormControlLabel control={<Radio />} label="Multi Office" value="MULTI_OFFICE" />
                      </RadioGroup>
                    </>)
                  } />
                <Controller control={control}
                  name="secondaryType"
                  render={({ field: { onChange, ref } }) => (
                    <>
                      <FormLabel component="legend" id="mailbox-type-label" required>
                        Secondary Type
                      </FormLabel>
                      <RadioGroup defaultValue={initialValues.secondaryType} row onChange={onChange} ref={ref}>
                        <FormControlLabel control={<Radio />} label="Current" value="CURRENT" />
                        <FormControlLabel control={<Radio />} label="Archive" value="ARCHIVE" />
                      </RadioGroup>
                    </>)
                  } />
                <TextField label="Customer Id" helperText="Enter Customer Id" error={!!errors.customerId} required className='mt-3' {...register('customerId', { required: 'Customer Id is Required' })} />
                <Button
                  type="reset"
                  onClick={() => {
                    reset();
                  }}
                >
                  Clear
                </Button>
                <Button type="submit" color="primary" className="float-right">
                  Submit
                </Button>
              </form>
            </Container>
          </BlockUi>
        </CardContent>
      </Card>
      {data ? (
        <Card className="mt-4">
          <CardHeader title="Temporary Access URL" />
          <CardContent>
            {!!clipboardState?.error?.message && <Alert severity="error">
              There was an error copying the temporary access url to your clipboard
            </Alert>}
            <Input value={data} readOnly style={{ maxWidth: '92%' }} fullWidth />
            <Button
              style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
              disabled={copied}
              color="primary"
              onClick={() => handleCopy()}
            >
              {copied ? <FileIcon /> : 'Copy'}
            </Button>
            <div className="pt-2">Temporary Access URLs are active for 10 minutes.</div>
          </CardContent>
        </Card>
      ) : null}
    </Container>
  );
};
