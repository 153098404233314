/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Card as RSCard, CardHeader as RSCardHeader, CardBody as RSCardBody, Button as RSButton, Alert as RSAlert, Row as RSRow, Col as RSCol } from 'reactstrap';
import { Alert, Autocomplete, Box, BlockUi, Button, Card, CardHeader, CardContent, Container, FormControl, FormLabel, FormControlLabel, Radio, TextField } from '@availity/element';
import RadioGroup from '@mui/material/RadioGroup';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { useMount } from '@availity/hooks';
import _get from 'lodash/get';

import { getMailboxes, getUser, AdminFoldersResponse } from '@/api';
import type { UserConfig, Folder } from '@/types';
import { useQueryParams } from '@/hooks';

import { AdminFileSystemTree, AdminFileViewer, FolderTable, UserInfo } from './components';
import { USER, MBX, SearchFormValues, SUBMITTER, RECEIVER, ERA, EROOM, MULTIOFFICE, CURRENT, ARCHIVE } from '../../constants';

type SecondaryMbxTypeValue = typeof CURRENT | typeof ARCHIVE;

type SearchTypeValue = typeof USER | typeof MBX;

const mbxTypeOptions: { label: string; value: string }[] = [
  { label: 'Submitter', value: SUBMITTER },
  { label: 'Receiver', value: RECEIVER },
  { label: 'ERA Sender', value: ERA },
  { label: 'ERoom', value: EROOM },
  { label: 'Multiple Office', value: MULTIOFFICE }
];

const findMbxTypeOption = (value: string) => mbxTypeOptions.find(o => o.value === value);

const initialValues: SearchFormValues = {
  searchType: MBX,
  mbxType: mbxTypeOptions[0].value,
  secondaryMbxType: CURRENT,
  mbx: '',
  username: '',
};

const schema = yup.object().shape({
  searchType: yup.string().oneOf([MBX, USER], 'Invalid search type set').required('Search Type is required.'),
  // Only require when USER search type is active
  username: yup.string().when('searchType', {
    is: USER,
    // eslint-disable-next-line unicorn/no-thenable
    then: yup.string().required('Username is required'),
  }),
  mbxType: yup.string().when('searchType', {
    is: MBX,
    // eslint-disable-next-line unicorn/no-thenable
    then: yup
      .string()
      .oneOf([SUBMITTER, RECEIVER, ERA, EROOM, MULTIOFFICE], 'The selected search type is not valid')
      .required('You must select a search type'),
  }),
  mbx: yup.string(),
});

function isUserConfig(value: any): value is UserConfig {
  return !!value?.username;
}

function isAdminFoldersResponse(value: any): value is AdminFoldersResponse {
  return !!value?.folders;
}

let setValuesCopy;
let submitFormCopy;

const SearchForm = ({ onSubmit, setSearchType }: { onSubmit: (data: SearchFormValues) => void, setSearchType: (newValue: SearchTypeValue) => void }) => {
  const { register, handleSubmit, formState: { errors }, control, setValue, trigger } = useForm<SearchFormValues>({ defaultValues: initialValues, mode: 'onTouched', resolver: yupResolver(schema) });

  const setValues = useCallback((values: SearchFormValues) => {
    for (const [key, value] of Object.entries(values)) {
      setValue(key as keyof SearchFormValues, value);
    }
  }, [setValue]);

  const submitForm = useCallback(async () => {
    // Trigger validations before submitting
    const isValid = await trigger();

    if (isValid) {
      handleSubmit(onSubmit)();
    }
  }, [handleSubmit, onSubmit, trigger]);

  const { target } = useQueryParams();
  useMount(() => {
    if (target && /(SUBMITTER|RECEIVER|ERASENDER|E-ROOMS|MULTIOFFICE)\/(CURRENT|ARCHIVE)\/.*/.test(target)) {
      const [mbxType, secondaryMbxType, mbx] = target.split('/');
      setValue('mbxType', mbxType);
      setValue('secondaryMbxType', secondaryMbxType);
      setValue('mbx', mbx);
      submitForm();
    }
  });

  useEffect(() => {
    setValuesCopy = setValues;
    submitFormCopy = submitForm;
  }, [setValues, submitForm]);

  const isMbxSearch = useWatch({ name: "searchType", control }) === MBX;
  const isSubmitterMbxSelected = useWatch({ name: "mbxType", control }) === SUBMITTER;

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <FormControl margin="normal">
        <FormLabel id="searchType-label" className="legend" component="div">What are you searching for?</FormLabel>
        <Controller
          control={control}
          name="searchType"
          render={({ field }) => (
            <RadioGroup
              {...field}
              aria-labelledby="searchType-label"
              row
              onChange={(event) => { field.onChange(event); setSearchType(event.target.value as SearchTypeValue) }}
            >
              <FormControlLabel control={<Radio />} value={MBX} label="Mailbox" />
              <FormControlLabel control={<Radio />} value={USER} label="User" />
            </RadioGroup>
          )}
        />
      </FormControl>
      {isMbxSearch ? (
        <>
          <Controller
            name="mbxType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                options={mbxTypeOptions}
                defaultValue={findMbxTypeOption(initialValues.mbxType)}
                value={findMbxTypeOption(value)}
                disableClearable
                onChange={(e, value) => {
                  onChange(value !== null ? value.value : initialValues.mbxType)
                  // Reset mbx field
                  setValue('mbx', '');
                }}
                FieldProps={{
                  label: "Mailbox Type",
                  required: true,
                  error: Boolean(errors.mbxType),
                  helperText: errors.mbxType?.message,
                  margin: "normal",
                }}
              />
            )}
          />
          <TextField
            {...register('mbx')}
            label={isSubmitterMbxSelected ? 'Customer ID' : 'Mailbox'}
            error={Boolean(errors.mbx)}
            margin="normal"
            helperText={
              isSubmitterMbxSelected ? (
                <>
                  Note: Search criteria is case sensitive.
                  <br />
                  The Customer ID you enter will have 0s prefixed to the beginning. Eg: 1194 will become 0001194
                </>
              ) : (
                'Note: Search criteria is case sensitive.'
              )
            }
          />
        </>
      ) : (
        <TextField
          {...register('username')}
          label="Username"
          helperText={
            <>
              {errors.username ? <>{errors.username.message}<br /></> : null}
              Note: Search criteria is case sensitive.
            </>
          }
          required
          error={Boolean(errors.username)}
          margin="normal"
        />
      )}
      <Button sx={{ display: 'flex', ml: 'auto' }} type="submit" color="primary">
        Search
      </Button>
    </Box>
  );
};

const ROOT = '/';
const initialFolder: Folder = { data: ROOT, key: ROOT, label: 'Home', leaf: false };

export const Search = () => {
  const [searchType, setSearchType] = useState<SearchTypeValue>(initialValues.searchType);
  const [selectedFolder, setSelectedFolder] = useState<Folder | null>(null);
  const [hasFolderError, setHasFolderError] = useState(false);
  const [target, setTarget] = useState('');
  const [secondaryMbxType, setSecondaryMbxType] = useState<SecondaryMbxTypeValue>(CURRENT);

  const queryClient = useQueryClient();

  const {
    mutate: handleSearch,
    isLoading,
    isError,
    error,
    data = null,
    variables,
  } = useMutation<UserConfig | AdminFoldersResponse | null, unknown, SearchFormValues, unknown>(
    async ({ searchType, mbxType, mbx, username }) => {
      // Call mailbox admin api
      if (searchType === MBX) {
        mbx = mbx && mbxType === SUBMITTER ? mbx.padStart(7, '0') : mbx;
        const _secondaryMbxType = mbxType === EROOM ? '' : `${secondaryMbxType}/`;
        // Build the prefix and pagination token
        const prefix = `${mbxType}/${_secondaryMbxType}${mbx}`;
        const nextPageToken = undefined;

        // Fetch list of mailboxes
        const response = await getMailboxes(prefix, nextPageToken);
        queryClient.setQueryData(['mbx-list', prefix, nextPageToken], response);
        return response;
      }

      // Call user admin api
      if (searchType === USER) {
        const response = await getUser(username);
        queryClient.setQueryData(['user-config', username], response);
        return response;
      }

      return null;
    }
  );

  const onSearchSubmit = (values: SearchFormValues) => {
    // Reset folder and target
    setSelectedFolder(null);
    setTarget('');

    // Call endpoint
    handleSearch(values);
  }

  const toggleSecondaryMbxType = () => {
    const newType = secondaryMbxType === CURRENT ? ARCHIVE : CURRENT;
    setSecondaryMbxType(newType);
    setSelectedFolder(initialFolder);
    // TODO: make sure we only replace first instance of it
    setTarget((prev) => prev.replace(secondaryMbxType, newType));
  };

  const originalMbx = variables?.mbx;
  const mbx = originalMbx && variables?.mbxType === SUBMITTER ? originalMbx.padStart(7, '0') : originalMbx;
  let prefix;
  if (variables) {
    prefix = variables.mbxType === EROOM ? `E-Rooms/${mbx || ''}` : `${variables.mbxType}/${secondaryMbxType}/${mbx || ''}`
  } else {
    prefix = '';
  }

  return (
    <>
      <Container>
        <Card sx={{ mb: 2 }}>
          <CardHeader title="Search" titleTypographyProps={{ component: "h2" }} />
          <BlockUi tag={CardContent} blocking={isLoading}>
            <Box maxWidth='700px' margin='auto'>
              {isError ? <Alert severity="error">
                {_get(error, 'response.data.errors[0].message', 'There was an error completing the search')}
              </Alert> : null}
              <SearchForm setSearchType={setSearchType} onSubmit={onSearchSubmit} />
            </Box>
          </BlockUi>
        </Card>
        {searchType === USER && isUserConfig(data) && <UserInfo handleSearch={handleSearch} setValuesCopy={setValuesCopy} submitFormCopy={submitFormCopy} setSearchType={setSearchType} setSecondaryMbxType={setSecondaryMbxType} username={data.username} />}
        {searchType === MBX && isAdminFoldersResponse(data) && (
          <FolderTable
            prefix={prefix || ''}
            mbx={mbx}
            mbxType={variables.mbxType}
            setValuesCopy={setValuesCopy}
            setSearchType={setSearchType}
            submitFormCopy={submitFormCopy}
            originalMbx={originalMbx}
            folders={data.folders}
            onFolderSelect={(folder) => {
              // Remove trailing slash
              setTarget(folder.length > 1 ? folder.slice(0, -1) : folder);
              setSelectedFolder(initialFolder);
            }}
          />
        )}
      </Container>
      {searchType === MBX && selectedFolder && target && (
        <>
          <RSRow className="mb-3">
            <RSCol className="d-flex flex-row align-items-center">
              <h3 id="folder-target-header" className="h4 mr-3 mb-0">
                Viewing folders for: {target}
              </h3>
              <RSButton onClick={toggleSecondaryMbxType} className="float-right">
                {secondaryMbxType === CURRENT ? 'View Archive' : 'View Current'}
              </RSButton>
            </RSCol>
          </RSRow>
          <RSAlert className="mb-3" isOpen={hasFolderError} color="danger">
            There was an error retrieving the available folders.
          </RSAlert>
          <RSRow style={{ minHeight: '800px' }} id="file-viewer-row">
            <RSCol xs="3">
              <RSCard>
                <RSCardHeader>
                  <h4 className="h5">Folders</h4>
                </RSCardHeader>
                <RSCardBody>
                  <AdminFileSystemTree
                    selectedFolder={selectedFolder}
                    setSelectedFolder={setSelectedFolder}
                    setHasFolderError={setHasFolderError}
                    target={target}
                    folder={initialFolder}
                    isRoot
                  />
                </RSCardBody>
              </RSCard>
            </RSCol>
            <RSCol>
              <RSCard>
                <RSCardBody>
                  <AdminFileViewer selectedFolder={selectedFolder} target={target} />
                </RSCardBody>
              </RSCard>
            </RSCol>
          </RSRow>
        </>
      )}
    </>
  );
};
