import { AvMicroserviceApi } from '@availity/api-axios';
import moment from 'moment';

import type { MFTNodes, MailboxConfig, UserConfig } from '@/types';

import { logInfo, logError } from './log';

export const SendReceiveEDIApi = new AvMicroserviceApi({
  path: '/ms/api/availity/internal/mft/mft-onprem-api',
  headers: {
    'X-Client-ID': 'test',
  },
});

export const SendReceiveEDIApiAdminMailbox = new AvMicroserviceApi({
  path: '/ms/api/availity/internal/mft/mft-onprem-api/admin/mailbox',
  headers: {
    'X-Client-ID': 'test',
  },
});

const FOLDER = 'folder';
const FILE = 'file';

/**
 * Fetch folders and files for the given directory and org
 */
export const fetchAllNodes = async (name: string, customerId: string) => {
  const request = {
    node_name: name,
    customer_id: customerId,
  };

  try {
    // <MFTNodes> allows us to specify what the data property of
    // the axios response will be, e.g. MFTNodes since it would
    // otherwise be any
    const resp = await SendReceiveEDIApi.post<MFTNodes>(request, {
      name: 'api/listchildnodes',
      polling: true,
      pollingMethod: 'POST',
      pollingIntervals: [1e3, 2e3, 5e3, 1e4, 15e3, 2e4, 3e4], // in ms
    });

    logInfo('get nodes', customerId, { cnt: resp.data.length, pth: name });

    return resp.data;
  } catch {
    logError('get nodes', customerId, { pth: name });
    throw new Error(`Unable to fetch ${name}`);
  }
};

/**
 * Delete the folder or file node with the given name
 */
export const deleteNode = async (type: 'file' | 'folder', name: string, customerId: string) => {
  const request = {
    node_type: type,
    node_name: name,
    customer_id: customerId,
  };

  try {
    const response = await SendReceiveEDIApi.post(request, {
      name: 'api/delete',
    });
    return response.data;
  } catch {
    throw new Error(`There was an error deleting node ${name} of type ${type}`);
  }
};

/**
 * Delete the folder with the given name
 */
export const deleteFolder = async (name: string, customerId: string) => {
  const response = await deleteNode(FOLDER, name, customerId);
  return response;
};

/**
 * Delete the file with the given name
 */
export const deleteFile = async (name: string, customerId: string) => {
  const response = await deleteNode(FILE, name, customerId);
  return response;
};

/**
 * Create a folder with the given name
 */
export const createFolder = async (name: string, path: string) => {
  const request = {
    node_type: FOLDER,
    node_name: name,
    new_node_path: path,
  };

  try {
    const response = await SendReceiveEDIApi.post(request, {
      name: 'api/createfolder',
    });
    return response;
  } catch {
    throw new Error(`There was an error deleting node ${name} of type ${FOLDER}`);
  }
};

/**
 * Upload a file with the given name
 */
export const uploadFile = async (name: string, path: string, file: File, customerId: string) => {
  const request = new FormData();
  request.append('file_name', name);
  request.append('file_path', path);
  request.append('filetoupload', file);
  request.append('customer_id', customerId);

  try {
    const response = await SendReceiveEDIApi.post(request, {
      name: 'api/upload',
      timeout: Number.POSITIVE_INFINITY,
    });
    return response.data;
  } catch {
    throw new Error(`There was an error uploading the file ${name}`);
  }
};

/**
 * Download a file with the given name
 */
export const downloadFile = async (path: string, customerId: string) => {
  const request = {
    path,
    customer_id: customerId,
  };

  try {
    const response = await SendReceiveEDIApi.post(request, { responseType: 'blob', name: 'api/download' });

    const filename = response.headers['content-disposition']?.split('filename=')[1];

    return { file: response.data, filename, type: response.headers['content-type'] };
  } catch {
    throw new Error(`There was an error downloading the file`);
  }
};

// ----- ADMIN -----

/**
 * Download a file
 */
export const adminDownloadFile = async (mailboxTarget: string, path: string) => {
  const request = {
    mailboxTarget,
    path,
  };

  try {
    const response = await SendReceiveEDIApi.post(request, { responseType: 'blob', name: 'admin/download' });
    const filename = response.headers['content-disposition']?.split('filename=')[1];

    return { file: response.data, filename, type: response.headers['content-type'] };
  } catch {
    throw new Error(`There was an error downloading the file`);
  }
};
/**
 * Get all user configs
 */
export const getUsers = async (username?: string) => {
  const params = username ? { username } : undefined;
  const response = await SendReceiveEDIApi.query<{ users: UserConfig[] }>({ name: '/admin/user', params });

  if (response.data.users) {
    return response.data.users.map((user) => ({
      ...user,
      createdAt: moment(Math.abs(+user.createdAt)).format('MM/DD/YYYY h:mm a'),
      updatedAt: moment(Math.abs(+user.updatedAt)).format('MM/DD/YYYY h:mm a'),
      lastLogin: user.lastLogin ? moment(Math.abs(+user.lastLogin)).format('MM/DD/YYYY h:mm a') : null,
    }));
  }

  return response.data;
};

/**
 * Get all user configs
 */
export const getUser = async (username: string) => {
  const response = await SendReceiveEDIApi.query<UserConfig>({
    name: '/admin/user',
    params: { username },
  });

  return {
    ...response.data,
    createdAt: response.data.createdAt ? moment(Math.abs(+response.data.createdAt)).format('MM/DD/YYYY h:mm a') : '',
    updatedAt: response.data.updatedAt ? moment(Math.abs(+response.data.updatedAt)).format('MM/DD/YYYY h:mm a') : '',
    lastLogin: response.data.lastLogin ? moment(Math.abs(+response.data.lastLogin)).format('MM/DD/YYYY h:mm a') : '',
  };
};

type GetTempAccessUrlInput = {
  customerId: string;
  mbxType: string;
  secondaryType: string;
};

type UserMailboxInput = {
  id: string;
  entry: string;
  target: string;
};

type CreateUserInput = {
  username: string;
  password: string;
  version?: number;
  email?: string;
  phone?: string;
  notes?: string;
  publicKeys?: string[];
  mailboxes?: UserMailboxInput[];
  accountLocked?: boolean;
  accountDisabled?: boolean;
  passwordExpired?: boolean;
};

/**
 * Create a new user
 */
export const createUser = async (request: CreateUserInput) => {
  const response = await SendReceiveEDIApi.post({ createUserInput: { ...request } }, { name: '/admin/user' });

  return response.data;
};

type UpdateUserInput = {
  username: string;
  email?: string | null;
  phone?: string | null;
  notes?: string | null;
  publicKeys?: string[];
  accountLocked?: boolean;
  accountDisabled?: boolean;
  passwordExpired?: boolean;
};

/**
 * Update info for given user
 */
export const editUser = async (request: UpdateUserInput) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const response = await SendReceiveEDIApi.put({ updateUserInput: { ...request } }, { name: '/admin/user' });

  return response.data;
};

export type AdminFoldersResponse = {
  folders: string[];
  nextPageToken: string | null;
};

/**
 * Get all mailbox configs
 */
export const getMailboxes = async (prefix: string, nextPageToken?: string) => {
  const response = await SendReceiveEDIApi.query<AdminFoldersResponse>({
    name: '/admin/mailbox',
    params: { prefix, nextPageToken },
    polling: true,
    pollingIntervals: [1e3, 2e3, 5e3, 1e4, 15e3, 2e4, 3e4], // in ms
  });
  return response.data;
};

export type AdminMailboxResponse = { mailbox: MailboxConfig | null; nodes: MFTNodes };

/**
 * Get all mailbox configs
 */
export const getMailbox = async ({ target, nodeName }: { target: string; nodeName?: string }) => {
  const response = await SendReceiveEDIApi.query<AdminMailboxResponse>({
    name: '/admin/mailbox',
    params: { target, node_name: nodeName },
    polling: true,
    pollingIntervals: [1e3, 2e3, 5e3, 1e4, 15e3, 2e4, 3e4], // in ms
    pollingMethod: 'GET',
  });

  return response.data;
};

type UpdateMailboxInput = {
  target: string;
  updateMailboxInput: {
    version?: number;
    target?: string;
    archiveTarget?: string;
    template?: string;
    type?: string;
    sourceType?: string;
    customerType?: string;
    archiveFormat?: string;
    fileRoutingRules?: {
      ruleType: string;
      queueUSEast1: string;
      queueUSWest2: string;
      pattern?: string;
      roleARN?: string;
    }[];
  };
};

/**
 * Update info for given mailbox
 */
export const editMailbox = async (request: UpdateMailboxInput) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const response = await SendReceiveEDIApi.put(request, { name: '/admin/mailbox' });

  return response.data;
};

type CreateMailboxInput = {
  target: string;
  archiveTarget?: string;
  template: string;
};

type CreateMailboxResponse = {
  archiveFormat: string;
  archiveTarget: string;
  createdAt: number;
  customerType: string;
  sourceType: string;
  target: string;
  template: string;
  type: string;
  version: number;
  errors?: unknown[];
};

type ResetUserPasswordInput = {
  username: string;
};

type UpdateUserPasswordInput = {
  username: string;
  currentPassword: string;
  newPassword: string;
};

/**
 * Create a new mailbox
 */
export const createMailbox = async (request: CreateMailboxInput) => {
  const response = await SendReceiveEDIApi.post<CreateMailboxResponse>(
    { createMailboxInput: request },
    { name: '/admin/mailbox' }
  );

  return response.data;
};

export const getTempAccessUrl = async (request: GetTempAccessUrlInput) => {
  const response = await SendReceiveEDIApi.query<string>({
    name: `/admin/temp-access-url`,
    params: { customerId: request.customerId, mbxType: request.mbxType, secondaryType: request.secondaryType },
  });
  return response.data;
};

export const updateUserPassword = async (request: UpdateUserPasswordInput) => {
  const response = await SendReceiveEDIApi.put(request, { name: '/admin/user/password' });

  return response.data;
};

export const resetUserPassword = async (request: ResetUserPasswordInput) => {
  const response = await SendReceiveEDIApi.put(request, { name: '/admin/user/resetpassword' });

  return response.data;
};
