import React from 'react';
import { Container } from 'reactstrap';

export const Unauthorized = () => {
  return (
    <Container>
      <p>
        You are not authorized to see this content. Please contact your administrator to have the AV Internal Links Page Role (904) assigned.
      </p>
    </Container>
  );
};
